import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import { BasicSelect, ModelSelect } from 'vue-search-select';
// import { required } from 'vuelidate/lib/validators';
export default {
  name: '',
  components: {
    BasicSelect,
    ModelSelect
  },
  props: {},
  data() {
    return {
      payload: null,
      unsubscribe: null,
      totalRows: null,
      currentPage: 1,
      showValueSetModal: false,
      setTimeVsetCode: null,
      valueSetName: null,
      parent_value_set_id: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      data: [],
      selectedLegalEntity: {
        value: null,
        text: null
      },
      selectedITCType: {
        value: null,
        text: null
      },
      periodicalType: {
        value: null,
        text: null
      },
      financialYear: {
        value: null,
        text: null
      },
      itcSupplierSummarydata: [],
      GSTNumberTypeList: [],
      legalEntityList: [],
      ITCTypeList: [
        {
          value: '2A',
          text: '2A'
        },
        {
          value: '2B',
          text: '2B'
        }
      ],
      fields: [
        {
          key: 'itc_type',
          label: 'ITC Type'
        },
        {
          key: 'fy',
          label: 'Financial Year'
        },
        {
          key: 'periodical_type'
        },
        {
          key: 'gstn_no_supp',
          label: 'Supplier GSTN'
        },
        {
          key: 'total_invoices'
        },
        {
          key: 'total_taxable_value'
        },
        {
          key: 'total_tax'
        },
        {
          key: 'reconciled_invoices',
          label: 'Total Reconciled'
        },
        {
          key: 'reconciled_taxable_value'
        },
        {
          key: 'reconciled_tax'
        },
        {
          key: 'tax_diff',
          label: 'Tax Difference'
        }
      ]
    };
  },
  //   validations: {
  //     selectedLegalEntity: {
  //       text: {
  //         required
  //       }
  //     },
  //     selectedITCType: {
  //       text: {
  //         required
  //       }
  //     },
  //   },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showChildModal = true;
        }
      }
    });
  },
  methods: {
    getItcSupplierSummary() {
      //   this.$v.$touch();
      //   if (!this.$v.$invalid) {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.selectedLegalEntity.value,
        itc_type: this.selectedITCType.value,
        fy: this.financialYear.value,
        periodical_type: this.periodicalType.value
      };
      this.loader = true;
      this.$store
        .dispatch('itcSummaryReport/getItcSupplierSummary', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.itcSupplierSummarydata = resp.data.page;
            this.totalRows = resp.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
      //   }
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.financialYear = {
          value: null,
          text: null
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_PERIODICAL_TYPE
      ) {
        this.periodicalType = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FINANCIAL_YEAR_LIST
      ) {
        this.financialYear = {
          value: item.value_code,
          text: item.value_code
        };
      }
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.FINANCIAL_YEAR_LIST) {
        this.parent_value_set_id = this.selectedLegalEntity.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clear() {
      this.selectedLegalEntity = {
        value: null,
        text: null
      };
      this.selectedITCType = {
        value: null,
        text: null
      };
      this.financialYear = {
        value: null,
        text: null
      };
      this.periodicalType = {
        value: null,
        text: null
      };
      this.itcSupplierSummarydata = [];
    },
    rowSelected() {},
    clearVsetValues(vsetCode) {
      if (vsetCode === this.selectedLegalEntity.value) {
        this.selectedLegalEntity = {
          value: null,
          text: null
        };
        this.financialYear = {
          value: null,
          text: null
        };
      } else if (vsetCode === this.periodicalType.value) {
        this.periodicalType = {
          value: null,
          text: null
        };
      } else if (vsetCode === this.financialYear.value) {
        this.financialYear = {
          value: null,
          text: null
        };
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
    this.unsubscribe();
  }
};
